/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {ServerError, useLazyQuery, useMutation} from '@apollo/client'

import {Step1Sub1} from './Step1Sub1'
import {Step1Sub2} from './Step1Sub2'
import {Step1Sub3} from './Step1Sub3'
import Alert from '../../../../_metronic/partials/alert'
import mutations from './../../../../setup/graphql/mutations'
import {fetchStop} from '../../../../redux/actions/common'
import {setCoApplicant, setOpenVerification, setSubStep} from '../redux/actions'
import query from '../../../../setup/graphql/query'

const Step1: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()
  const {id} = params

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [mobileNo, setMobileNo] = useState('')

  const subStep = useSelector((state: any) => state.coApplicant.subStep)
  const co_applicant = useSelector((state: any) => state.coApplicant.co_applicant)

  const [sendOTP, onSuccessSendOTP] = useMutation(mutations.SEND_OTP)
  const [validateOTP, onSucccessValidateOTP] = useMutation(mutations.VALIDATE_OTP)
  const location = useLocation()
  const searchParam: any[] = location.pathname.split('/')
  // const searchParamsData: string | null = searchParam[searchParam.length - 1] === "co-applicant" || searchParam[searchParam.length - 1] === "" ? null : searchParam[searchParam.length - 1]
  const [getCoApplicant, onSuccessGetCoApplicant] = useLazyQuery(query.GET_SHAREHOLDER)

  useEffect(() => {
    if (onSuccessSendOTP.called && !onSuccessSendOTP.loading) {
      dispatch(fetchStop())
      if (onSuccessSendOTP.data) {
        if (onSuccessSendOTP.data.generateOtp.success) {
          localStorage.removeItem('coApplicationId')
          localStorage.removeItem('singpass')
          history.push('/co-applicant/' + onSuccessSendOTP.data.generateOtp.id)
          dispatch(setSubStep(2))
          onSuccessSendOTP.reset()
        } else {
          setShowAlert(true)
          setAlertMsg(
            'eKYC is already verified using the link or the input mobile number does not match. Please verify and try again.'
          )
          setAlertType('primary')
          dispatch(fetchStop())
        }
      }

      if (onSuccessSendOTP.error) {
        const {networkError} = onSuccessSendOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [
    onSuccessSendOTP.loading,
    onSuccessSendOTP.data,
    onSuccessSendOTP.error,
    onSuccessSendOTP.called,
  ])

  useEffect(() => {
    if (onSucccessValidateOTP.called && !onSucccessValidateOTP.loading) {
      dispatch(fetchStop())
      if (onSucccessValidateOTP.data) {
        if (onSucccessValidateOTP.data.validateOtp.success) {
          if (co_applicant == null) {
            getCoApplicant({variables: {data: {id}}})
          } else {
            dispatch(setOpenVerification(true))
          }
          onSucccessValidateOTP.reset()
        } else {
          const message = onSucccessValidateOTP.data.validateOtp.message
          setShowAlert(true)
          setAlertMsg(message)
          setAlertType('primary')
        }
      }

      if (onSucccessValidateOTP.error) {
        const {networkError} = onSucccessValidateOTP.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
      }
    }
  }, [
    onSucccessValidateOTP.loading,
    onSucccessValidateOTP.data,
    onSucccessValidateOTP.error,
    onSucccessValidateOTP.called,
  ])

  useEffect(() => {
    if (onSuccessGetCoApplicant.data && !onSuccessGetCoApplicant.error) {
      dispatch(fetchStop())
      if (subStep != 3) dispatch(setOpenVerification(true))
      dispatch(setCoApplicant(onSuccessGetCoApplicant.data.shareholderKycStatus))
    }
  }, [onSuccessGetCoApplicant.data, onSuccessGetCoApplicant.error, onSuccessGetCoApplicant.loading])

  return (
    <>
      {subStep === 1 && <Step1Sub1 sendOTP={sendOTP} setMobile={setMobileNo} id={id} />}
      {subStep === 2 && (
        <Step1Sub2 mobileNo={mobileNo} sendOTP={sendOTP} validateOTP={validateOTP} id={id} />
      )}
      {subStep === 3 && <Step1Sub3 getCoApplicant={getCoApplicant} />}
      {/* {subStep === 4 && <Step1Sub4 {...additionalProps} />} */}

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
    </>
  )
}

export {Step1}
