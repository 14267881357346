import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from 'react-phone-number-input'
import NumberFormat from 'react-number-format'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { trim } from '../../../../_metronic/helpers'
import { fetchStartWithForward } from '../../../../redux/actions/common'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const errorMsg = {
  required: 'Required',
  amountRange: 'Amount range should be between 75,000 - 250,000',
  // durationRange: 'Duration range should be between 3 - 12 months',
}

const industryOptions = [
  { label: 'Service Sector – F&B', value: 'service-sector-fb', rating: 'H', pricing: '21' },
  { label: 'Service Sector – non-F&B', value: 'service-sector-non-fb', rating: 'M', pricing: '15' },
  { label: 'Health & Human Services', value: 'health-human-services', rating: 'M', pricing: '15' },
  { label: 'Education', value: 'education', rating: 'L', pricing: '8' },
  { label: 'IT services', value: 'it-services', rating: 'L', pricing: '8' },
  { label: 'Media', value: 'media', rating: 'M', pricing: '15' },
  {
    label: 'Agriculture / Fishing / Food Production',
    value: 'agriculture-fishing-foodproduction',
    rating: 'H',
    pricing: '21',
  },
  { label: 'Wholesale Trader', value: 'wholesale-trader', rating: 'M', pricing: '15' },
  { label: 'Retail Trading', value: 'retail-trading', rating: 'M', pricing: '15' },
  {
    label: 'Manufacturing – heavy industries',
    value: 'manufacturing-heavy-industries',
    rating: 'M',
    pricing: '15',
  },
  {
    label: 'Manufacturing – consumer related',
    value: 'manufacturing-consumer-related',
    rating: 'M',
    pricing: '15',
  },
  {
    label: 'Manufacturing – computer & electronics',
    value: 'manufacturing-computer-electronics',
    rating: 'L',
    pricing: '8',
  },
  {
    label: 'Energy – generation and distribution',
    value: 'energy-generation-distribution',
    rating: 'H',
    pricing: '21',
  },
  { label: 'Transport & Logistics', value: 'transport-logistics', rating: 'M', pricing: '15' },
  { label: 'Renewable', value: 'renewable', rating: 'M', pricing: '15' },
  {
    label: 'Building and Construction - Private Sector',
    value: 'building-construction-private',
    rating: 'H',
    pricing: '21',
  },
  {
    label: 'Building and Construction - Public Sector',
    value: 'building-construction-public',
    rating: 'L',
    pricing: '8',
  },
  { label: 'Other', value: 'other', rating: 'M', pricing: '15' },
]

const loanPurpose = [
  { label: 'Business Expansion', value: 'business-expansion' },
  { label: 'Pay Invoices', value: 'pay-invoices' },
  { label: 'Equipment Purchase', value: 'equipment-purchase' },
  { label: 'Infrastructure Upgrades', value: 'infrastructure-upgrades' },
  { label: 'Working Capital', value: 'working-capital' },
  { label: 'Other', value: 'other' },
]

const maxAmount = 250000
const minAmount = 75000
const maxDuration = 12
const minDuration = 3
const today = new Date()
// const fourDigitDate = new Date('1000');

const Step1Sub3: FC<any> = ({
  createLoanEligibility,
  updateLoanElgibility,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  /* 
  const [date, setDate] = useState(null)
  const [lastPickedDate, setLastPickedDate] = useState<any>(null);
  const [lastPickedDateError, setLastPickedDateError] = useState<boolean>(false); */

  const loanApplication = useSelector((state: any) => state.partnerConnect.partnerApplication)
  const loanEligibility = useSelector((state: any) => state.partnerConnect.partnerEligibility)

  const initialValues = {
    loanPurpose: loanEligibility ? loanEligibility?.loanPurpose : '',
    amountRequested: loanApplication ? loanApplication.amountRequested : '',
    // durationInMonths: loanApplication ? loanApplication.durationInMonths : '',
    companyUEN: loanApplication ? loanApplication.companyUEN : '',
    companyName: loanEligibility ? loanEligibility?.companyName : '',
    businessMobileNumber: loanEligibility
      ? loanEligibility?.businessMobileNumber
      : loanApplication.mobileNumber,
    businessEmailAddress: loanEligibility ? loanEligibility?.businessEmailAddress : '',
    industryType: loanEligibility ? loanEligibility?.industryType : '',
    turnover: loanEligibility ? loanEligibility?.turnover : 0,
    profit: loanEligibility ? loanEligibility?.profit : 0,
    totalMonthlyInstallmentsToBanks: loanEligibility
      ? loanEligibility?.totalMonthlyInstallmentsToBanks
      : 0,
    totalMonthlyInstallmentsToNonBanks: loanEligibility
      ? loanEligibility?.totalMonthlyInstallmentsToNonBanks
      : 0,
    totalRevolvingCreditOutstandingToBanks: loanEligibility
      ? loanEligibility?.totalRevolvingCreditOutstandingToBanks
      : 0,
    totalRevolvingCreditOutstandingToNonBanks: loanEligibility
      ? loanEligibility?.totalRevolvingCreditOutstandingToNonBanks
      : 0,
    currentLitigationAmount: loanEligibility ? loanEligibility?.currentLitigationAmount : 0,
    anyOutstandingDebts: loanEligibility ? loanEligibility?.anyOutstandingDebts : false,
    otherType: loanEligibility ? loanEligibility?.otherType : '',
    dateOfIncorporation: loanEligibility
      ? new Date(loanEligibility?.dateOfIncorporation)
      : undefined,
  }

  const validationSchema = Yup.object().shape({
    // loanPurpose: Yup.string().required(errorMsg.required),
    loanPurpose: Yup.string()
      .required('Please select a Loan Purpose')
      .test('mustSelect', 'Please select a Loan Purpose', (value: any) => {
        return value !== '-'
      }),
    amountRequested: Yup.number()
      .required(errorMsg.required)
      .min(minAmount, 'Amount range should be between 75,000 - 250,000')
      .max(maxAmount, 'Amount range should be between 75,000 - 250,000'),
    // durationInMonths: Yup.number()
    //   .required(errorMsg.required)
    //   .min(minDuration, 'Duration range should be between 3 - 12 months')
    //   .max(maxDuration, 'Duration range should be between 3 - 12 months')
    //   .test('isInteger', 'Must be an integer', (value: any) => {
    //     return Number.isInteger(value)
    //   }),
    companyUEN: Yup.string().required(errorMsg.required),
    companyName: Yup.string().required('Company Name is required'),
    businessMobileNumber: Yup.string()
      .min(11, 'Enter a 8-digit Mobile Number')
      .max(11, 'Enter a 8-digit Mobile Number')
      .required('Mobile Number is required')
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      }),
    businessEmailAddress: Yup.string()
      .required('Business Email Address is required')
      .email('Invalid Email'),
    dateOfIncorporation: Yup.string()
      .required('Date of Incorporation is required')
      .typeError('Date of Incorporation is required'),
    industryType: Yup.string()
      .required('Industry is required')
      .test('mustSelectType', 'Please select an Industry Type', (value: any) => {
        return value !== ''
      }),
    otherType: Yup.string().when('industryType', {
      is: (industryType: string) => industryType === 'other',
      then: Yup.string().required('Please enter your industry type'),
    }),
    turnover: Yup.number()
      .required('Turnover is required')
      .test('turnOverNotZero', 'Turnover cannot be 0', (value: any) => {
        return value !== 0
      }),
    profit: Yup.number()
      .required('Net Operating Income is required')
      .test('notZero', 'Net Operating income cannot be 0', (value: any) => {
        return value !== 0
      }),
    anyOutstandingDebts: Yup.boolean().required(errorMsg.required),
    totalMonthlyInstallmentsToBanks: Yup.number(),
    /*     .when(['anyOutstandingDebts', 'totalMonthlyInstallmentsToNonBanks', 'totalRevolvingCreditOutstandingToBanks', 'totalRevolvingCreditOutstandingToNonBanks'], {
      is: (anyOutstandingDebts: boolean, 
        totalMonthlyInstallmentsToNonBanks:number, 
        totalRevolvingCreditOutstandingToBanks:number, 
        totalRevolvingCreditOutstandingToNonBanks:number) => {
          if(anyOutstandingDebts && totalMonthlyInstallmentsToNonBanks <= 0 && totalRevolvingCreditOutstandingToBanks <= 0 && totalRevolvingCreditOutstandingToNonBanks <= 0){
            return true;
          }
          return false;
        },
      then: Yup.number().required('At least one field below should be greater than 0').test('notZero', 'At least one field below should be greater than 0', (value: any) => { return value <= 0 }),
    }), */
    totalMonthlyInstallmentsToNonBanks: Yup.number(),
    /*     .when(['anyOutstandingDebts', 'totalMonthlyInstallmentsToBanks', 'totalRevolvingCreditOutstandingToBanks', 'totalRevolvingCreditOutstandingToNonBanks'], {
      is: (anyOutstandingDebts: boolean, 
        totalMonthlyInstallmentsToBanks:number, 
        totalRevolvingCreditOutstandingToBanks:number, 
        totalRevolvingCreditOutstandingToNonBanks:number) => {
          if(anyOutstandingDebts && totalMonthlyInstallmentsToBanks <= 0 && totalRevolvingCreditOutstandingToBanks <= 0 && totalRevolvingCreditOutstandingToNonBanks <= 0){
            return true;
          }
          return false;
        },
      then: Yup.number().required('Monthly Installments to non-banks is required').test('notZero', 'Monthly Installments to non-banks cannot be 0', (value: any) => { return value <= 0 }),
    }), */
    totalRevolvingCreditOutstandingToBanks: Yup.number(),
    /*     .when(['anyOutstandingDebts', 'totalMonthlyInstallmentsToNonBanks', 'totalMonthlyInstallmentsToBanks', 'totalRevolvingCreditOutstandingToNonBanks'], {
      is: (anyOutstandingDebts: boolean, 
        totalMonthlyInstallmentsToNonBanks:number, 
        totalMonthlyInstallmentsToBanks:number, 
        totalRevolvingCreditOutstandingToNonBanks:number) => {
          if(anyOutstandingDebts && totalMonthlyInstallmentsToNonBanks <= 0 && totalMonthlyInstallmentsToBanks <= 0 && totalRevolvingCreditOutstandingToNonBanks <= 0){
            return true;
          }
          return false;
        },
      then: Yup.number().required('Revolving Credit outstanding to banks is required').test('notZero', 'Revolving Credit outstanding to banks cannot be 0', (value: any) => { return value <= 0 }),
    }), */
    totalRevolvingCreditOutstandingToNonBanks: Yup.number(),
    currentLitigationAmount: Yup.number(),
    /*     .when(['anyOutstandingDebts', 'totalMonthlyInstallmentsToNonBanks', 'totalRevolvingCreditOutstandingToBanks', 'totalMonthlyInstallmentsToBanks'], {
      is: (anyOutstandingDebts: boolean, 
        totalMonthlyInstallmentsToNonBanks:number, 
        totalRevolvingCreditOutstandingToBanks:number, 
        totalMonthlyInstallmentsToBanks:number) => {
          if(anyOutstandingDebts && totalMonthlyInstallmentsToNonBanks <= 0 && totalRevolvingCreditOutstandingToBanks <= 0 && totalMonthlyInstallmentsToBanks <= 0){
            return true;
          }
          return false;
        },
      then: Yup.number().required('Revolving Credit outstanding to non-banks is required').test('notZero', 'Revolving Credit outstanding to non-banks cannot be 0', (value: any) => { return value !== 0 }),
    }) */
  })

  /* 
  [
    ['anyOutstandingDebts', 'totalMonthlyInstallmentsToBanks'],
    ['anyOutstandingDebts', 'totalMonthlyInstallmentsToNonBanks'],
    ['anyOutstandingDebts', 'totalRevolvingCreditOutstandingToBanks'],
    ['anyOutstandingDebts', 'totalRevolvingCreditOutstandingToNonBanks'],

    ['totalMonthlyInstallmentsToBanks', 'totalMonthlyInstallmentsToNonBanks'],
    ['totalMonthlyInstallmentsToBanks', 'totalRevolvingCreditOutstandingToBanks'],
    ['totalMonthlyInstallmentsToBanks', 'totalRevolvingCreditOutstandingToNonBanks'],

    ['totalMonthlyInstallmentsToNonBanks', 'totalMonthlyInstallmentsToBanks'],
    ['totalMonthlyInstallmentsToNonBanks', 'totalRevolvingCreditOutstandingToBanks'],
    ['totalMonthlyInstallmentsToNonBanks', 'totalRevolvingCreditOutstandingToNonBanks'],

    ['totalMonthlyInstallmentsToNonBanks', 'totalMonthlyInstallmentsToBanks'],
    ['totalMonthlyInstallmentsToNonBanks', 'totalRevolvingCreditOutstandingToBanks'],
    ['totalMonthlyInstallmentsToNonBanks', 'totalRevolvingCreditOutstandingToNonBanks'],

    ['totalRevolvingCreditOutstandingToBanks', 'totalMonthlyInstallmentsToBanks'],
    ['totalRevolvingCreditOutstandingToBanks', 'totalMonthlyInstallmentsToNonBanks'],
    ['totalRevolvingCreditOutstandingToBanks', 'totalRevolvingCreditOutstandingToNonBanks'],

    ['totalRevolvingCreditOutstandingToNonBanks', 'totalMonthlyInstallmentsToBanks'],
    ['totalRevolvingCreditOutstandingToNonBanks', 'totalMonthlyInstallmentsToNonBanks'],
    ['totalRevolvingCreditOutstandingToNonBanks', 'totalRevolvingCreditOutstandingToBanks'],

  ]
  */

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      /* if(lastPickedDateError){
        formik.setFieldError('dateOfIncorporation', 'Please select valid Date');
        return;
      } */

      const {
        amountRequested,
        // durationInMonths,
        loanPurpose,
        companyName,
        businessMobileNumber,
        businessEmailAddress,
        industryType,
        otherType,
        turnover,
        profit,
        totalMonthlyInstallmentsToBanks,
        totalMonthlyInstallmentsToNonBanks,
        totalRevolvingCreditOutstandingToBanks,
        totalRevolvingCreditOutstandingToNonBanks,
        currentLitigationAmount,
        anyOutstandingDebts,
        dateOfIncorporation,
      } = trim(values)

      if (
        anyOutstandingDebts &&
        totalMonthlyInstallmentsToBanks <= 0 &&
        totalMonthlyInstallmentsToNonBanks <= 0 &&
        totalRevolvingCreditOutstandingToBanks <= 0 &&
        totalRevolvingCreditOutstandingToNonBanks <= 0 &&
        currentLitigationAmount <= 0
      ) {
        return
      }

      const principal = amountRequested
      const actualIntrestRate = Number(
        industryOptions.find((o: any) => o.value === industryType)?.pricing
      )
      // Monthly interest rate : Divide by 100 to convert to fraction
      const interestRate = actualIntrestRate / 1200
      // const tenure = durationInMonths
      //EMI = P × r × (1 + r) n/ ((1 + r) n - 1) where P= Loan amount, r= interest rate, n=tenure in number of months
      // const top = Math.pow(1 + interestRate, tenure)
      // const bottom = top - 1
      // const ratio = top / bottom
      // const emi = (principal * interestRate * ratio).toFixed(2)

      dispatch(fetchStartWithForward())
      updateApplication({
        variables: {
          data: {
            amountRequested: parseFloat(amountRequested),
            interestRate: Number(actualIntrestRate),
            mibCompanyName: companyName,
          },
          where: {
            id: loanApplication.id,
          },
        },
      })

      if (loanEligibility === null) {
        createLoanEligibility({
          variables: {
            data: {
              loanPurpose,
              companyName,
              businessMobileNumber,
              businessEmailAddress,
              industryType,
              otherType,
              dateOfIncorporation,
              turnover: parseInt(turnover),
              profit: parseInt(profit),
              anyOutstandingDebts,
              totalMonthlyInstallmentsToBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToBanks)
                : 0,
              totalMonthlyInstallmentsToNonBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToNonBanks)
                : 0,
              totalRevolvingCreditOutstandingToBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToBanks)
                : 0,
              totalRevolvingCreditOutstandingToNonBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToNonBanks)
                : 0,
              currentLitigationAmount: anyOutstandingDebts ? parseInt(currentLitigationAmount) : 0,
              application: {
                connect: {
                  id: loanApplication.id,
                },
              },
            },
          },
        })
      } else {
        updateLoanElgibility({
          variables: {
            data: {
              loanPurpose,
              companyName,
              businessMobileNumber,
              businessEmailAddress,
              industryType,
              otherType,
              dateOfIncorporation,
              turnover: parseInt(turnover),
              profit: parseInt(profit),
              anyOutstandingDebts,
              totalMonthlyInstallmentsToBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToBanks)
                : 0,
              totalMonthlyInstallmentsToNonBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToNonBanks)
                : 0,
              totalRevolvingCreditOutstandingToBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToBanks)
                : 0,
              totalRevolvingCreditOutstandingToNonBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToNonBanks)
                : 0,
              currentLitigationAmount: anyOutstandingDebts ? parseInt(currentLitigationAmount) : 0,
            },
            where: {
              id: loanEligibility.id,
            },
          },
        })
      }
      setSubmitting(false)
    },
  })

  /* useEffect(() => {
    if(lastPickedDateError){
      formik.setFieldError('dateOfIncorporation', 'Please select valid Date');
    }

  }, [formik.errors]) */

  return (
    <div className='d-flex flex-column'>
      <h2>Credit Line Details</h2>
      <div className='sub-heading text-muted mb-10'>
        We realize that your time is precious, and our platform will help complete this submission
        with just four steps
      </div>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Select Purpose
          </label>
          <div className='col-sm-9'>
            <select
              name='loanPurpose'
              className='form-select form-control-lg  form-select-solid'
              value={formik.values.loanPurpose}
              onChange={formik.handleChange}
            >
              <option value='-'>---- Select Purpose ----</option>
              {loanPurpose.map(({ label, value }: any, index: number) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {formik.touched.loanPurpose && formik.errors.loanPurpose && (
              <div className='text-danger mt-2'>{formik.errors.loanPurpose}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <div className='col-sm-6'>
            <div className='row'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-6'>
                Requested Credit Line
              </label>
              <div className='col-sm-6'>
                <NumberFormat
                  name='amountRequested'
                  value={formik.values.amountRequested}
                  className='form-control form-control-lg form-control-solid'
                  displayType={'input'}
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('amountRequested', value)
                  }}
                  onBlur={(event: any) => {
                    let { value } = event.target
                    value = value.substring(4)
                    value = parseFloat(value.replace(/,/g, ''))
                    value = Math.round(value / 500) * 500
                    value = Math.min(maxAmount, Math.max(minAmount, value))
                    formik.setFieldValue('amountRequested', value)
                  }}
                />
                {formik.touched.amountRequested && formik.errors.amountRequested && (
                  <div className='text-danger mt-2'>{formik.errors.amountRequested}</div>
                )}
              </div>
            </div>
          </div>
          {/* <div className='col-sm-6'>
            <div className='row'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-4'>
                Requested Duration
              </label>
              <div className='col-sm-4'>
                <input
                  name='durationInMonths'
                  type='number'
                  min={minDuration}
                  max={maxDuration}
                  className='form-control form-control-lg form-control-solid'
                  value={formik.values.durationInMonths}
                  onChange={formik.handleChange}
                // disabled
                />
                {formik.touched.durationInMonths && formik.errors.durationInMonths && (
                  <div className='text-danger mt-2'>{formik.errors.durationInMonths}</div>
                )}
              </div>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-4'>
                months
              </label>
            </div>
          </div> */}
        </div>
        <h3 className='mb-10'>Business Details</h3>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Company UEN/Number
          </label>
          <div className='col-sm-9'>
            <input
              name='companyUEN'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.companyUEN}
              onChange={formik.handleChange}
              disabled
            />
            {formik.touched.companyUEN && formik.errors.companyUEN && (
              <div className='text-danger mt-2'>{formik.errors.companyUEN}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Company Name
          </label>
          <div className='col-sm-9'>
            <input
              name='companyName'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.companyName}
              onChange={formik.handleChange}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <div className='text-danger mt-2'>{formik.errors.companyName}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Business Mobile Number
          </label>
          <div className='col-sm-9'>
            {/* <input 
              name="businessMobileNumber"
              type="text" 
              className="form-control form-control-lg form-control-solid" 
              value={formik.values.businessMobileNumber}
              onChange={formik.handleChange}
            /> */}
            <PhoneInput
              id='businessMobileNumber'
              international
              defaultCountry='SG'
              // countryCallingCodeEditable={false}
              value={formik.values.businessMobileNumber}
              onChange={(value) => formik.setFieldValue('businessMobileNumber', value)}
              placeholder='Enter phone number'
              className='int-phone-input'
              // readOnly
            />
            {formik.touched.businessMobileNumber && formik.errors.businessMobileNumber && (
              <div className='text-danger mt-2'>{formik.errors.businessMobileNumber}</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Business Email Address
          </label>
          <div className='col-sm-9'>
            <input
              name='businessEmailAddress'
              type='text'
              className='form-control form-control-lg form-control-solid'
              value={formik.values.businessEmailAddress}
              onChange={formik.handleChange}
            />
            {formik.touched.businessEmailAddress && formik.errors.businessEmailAddress && (
              <div className='text-danger mt-2'>{formik.errors.businessEmailAddress}</div>
            )}
          </div>
        </div>
        {/* <div className="row mb-10">
          <label htmlFor="" className="form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required">Date of Incorporation</label>
          <div className="col-sm-9">
            <DateTimePicker
              name="dateOfIncorporation"
              value={formik.values.dateOfIncorporation}
              //disableClock={true}
              format={"dd/MM/yyyy"}
              minDate={fourDigitDate}
              maxDate={today} //Due to this property got issue on manual date for greater year
              className="datepicker"
              onChange={(value: any) => {
                // formik.setFieldValue('dateOfIncorporation', value)
                setLastPickedDate(value)
              }}
              onCalendarOpen={() => {
                setLastPickedDate(null);
                setLastPickedDateError(false);
              }}
              onCalendarClose={() => {
                if(lastPickedDate <= today && lastPickedDate > fourDigitDate){
                  formik.setFieldValue('dateOfIncorporation', lastPickedDate);
                  setLastPickedDateError(false)
                } else {
                  setLastPickedDateError(true)
                  formik.setFieldError('dateOfIncorporation', 'Please select valid Date');
                }
              }}
            />
            <div className='text-danger mt-2'>{formik.errors.dateOfIncorporation} &nbsp;</div>
          </div>
        </div> */}
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Date of Incorporation
          </label>
          <div className='col-sm-9'>
            <DatePicker
              showYearDropdown
              scrollableYearDropdown
              yearDropdownItemNumber={100}
              dropdownMode='select'
              scrollableMonthYearDropdown
              showMonthDropdown
              placeholderText='Choose Date'
              required={true}
              maxDate={today}
              selected={formik.values.dateOfIncorporation}
              onChange={(value: any) => {
                formik.setFieldValue('dateOfIncorporation', value)
              }}
            />
            {formik.touched.dateOfIncorporation && formik.errors.dateOfIncorporation && (
              <div className='text-danger mt-2'>{formik.errors.dateOfIncorporation} &nbsp;</div>
            )}
          </div>
        </div>
        <div className='row mb-10'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Industry
          </label>
          <div className='col-sm-9'>
            <select
              name='industryType'
              className='form-select form-select-solid'
              value={formik.values.industryType}
              onChange={formik.handleChange}
            >
              <option value='-'>---- Select Industry option ----</option>
              {industryOptions.map(({ label, value }: any, index: number) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
            </select>
            {formik.touched.industryType && formik.errors.industryType && (
              <div className='text-danger mt-2'>{formik.errors.industryType}</div>
            )}
          </div>
        </div>
        {formik.values.industryType === 'other' && (
          <div className='row mb-10'>
            <label
              htmlFor=''
              className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'
            >
              Other Industry Type
            </label>
            <div className='col-sm-9'>
              <input
                name='otherType'
                type='text'
                className='form-control form-control-lg form-control-solid'
                value={formik.values.otherType}
                onChange={formik.handleChange}
              />
              {formik.touched.otherType && formik.errors.otherType && (
                <div className='text-danger mt-2'>{formik.errors.otherType}</div>
              )}
            </div>
          </div>
        )}

        <h3 className='mb-10'>Declare your Financials</h3>
        <div className='row mb-10'>
          <div className='col-sm-6'>
            <div className='row'>
              <label
                htmlFor=''
                className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-6 required'
              >
                Annual Turnover
              </label>
              <div className='col-sm-6'>
                <NumberFormat
                  name='turnover'
                  value={formik.values.turnover}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('turnover', value)
                  }}
                />
                {formik.touched.turnover && formik.errors.turnover && (
                  <div className='text-danger mt-2'>{formik.errors.turnover}</div>
                )}
              </div>
            </div>
          </div>
          <div className='col-sm-6'>
            <div className='row'>
              <label
                htmlFor=''
                className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-6 required'
              >
                Net Operating Income (profit)
              </label>
              <div className='col-sm-6'>
                <NumberFormat
                  name='profit'
                  value={formik.values.profit}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('profit', value)
                  }}
                />
                {formik.touched.profit && formik.errors.profit && (
                  <div className='text-danger mt-2'>{formik.errors.profit}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3 required'>
            Any Outstanding Loans/Debts
          </label>
          <div className='col-sm-9'>
            <div className='mb-10 d-flex'>
              <div className='form-check form-check-custom form-check-solid me-10'>
                <input
                  name='anyOutstandingDebts'
                  type='radio'
                  className='form-check-input'
                  checked={formik.values.anyOutstandingDebts}
                  onChange={() => formik.setFieldValue('anyOutstandingDebts', true)}
                />
                <label className='form-check-label'>Yes</label>
              </div>
              <div className='form-check form-check-custom form-check-solid'>
                <input
                  name='anyOutstandingDebts'
                  type='radio'
                  className='form-check-input'
                  checked={!formik.values.anyOutstandingDebts}
                  onChange={() => formik.setFieldValue('anyOutstandingDebts', false)}
                />
                <label className='form-check-label'>No</label>
              </div>
            </div>
          </div>
        </div>

        {formik.values.anyOutstandingDebts && (
          <>
            <div className='row mb-10'>
              {formik.values.anyOutstandingDebts &&
                formik.values.totalMonthlyInstallmentsToBanks <= 0 &&
                formik.values.totalMonthlyInstallmentsToNonBanks <= 0 &&
                formik.values.totalRevolvingCreditOutstandingToBanks <= 0 &&
                formik.values.totalRevolvingCreditOutstandingToNonBanks <= 0 &&
                formik.values.currentLitigationAmount <= 0 && (
                  <div className='text-danger mt-2'>
                    At least one field below should be greater than 0
                  </div>
                )}
            </div>
            <div className='row mb-10'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
                Total Monthly Installments to banks
              </label>
              <div className='col-sm-9'>
                <NumberFormat
                  name='totalMonthlyInstallmentsToBanks'
                  value={formik.values.totalMonthlyInstallmentsToBanks}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('totalMonthlyInstallmentsToBanks', value)
                  }}
                />
              </div>
            </div>

            <div className='row mb-10'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
                Total Monthly Installments to non-banks
              </label>
              <div className='col-sm-9'>
                <NumberFormat
                  name='totalMonthlyInstallmentsToNonBanks'
                  value={formik.values.totalMonthlyInstallmentsToNonBanks}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('totalMonthlyInstallmentsToNonBanks', value)
                  }}
                />
                {/* {formik.touched.totalMonthlyInstallmentsToNonBanks && formik.errors.totalMonthlyInstallmentsToNonBanks && (
                  <div className='text-danger mt-2'>{formik.errors.totalMonthlyInstallmentsToNonBanks}</div>
                )} */}
              </div>
            </div>

            <div className='row mb-10'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
                Total Revolving Credit outstanding to banks
              </label>
              <div className='col-sm-9'>
                <NumberFormat
                  name='totalRevolvingCreditOutstandingToBanks'
                  value={formik.values.totalRevolvingCreditOutstandingToBanks}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('totalRevolvingCreditOutstandingToBanks', value)
                  }}
                />
                {/* {formik.touched.totalRevolvingCreditOutstandingToBanks && formik.errors.totalRevolvingCreditOutstandingToBanks && (
                  <div className='text-danger mt-2'>{formik.errors.totalRevolvingCreditOutstandingToBanks}</div>
                )} */}
              </div>
            </div>

            <div className='row mb-10'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
                Total Revolving Credit outstanding to non-banks
              </label>
              <div className='col-sm-9'>
                <NumberFormat
                  name='totalRevolvingCreditOutstandingToNonBanks'
                  value={formik.values.totalRevolvingCreditOutstandingToNonBanks}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('totalRevolvingCreditOutstandingToNonBanks', value)
                  }}
                />
                {/* {formik.touched.totalRevolvingCreditOutstandingToNonBanks && formik.errors.totalRevolvingCreditOutstandingToNonBanks && (
                  <div className='text-danger mt-2'>{formik.errors.totalRevolvingCreditOutstandingToNonBanks}</div>
                )} */}
              </div>
            </div>
            <div className='row mb-10'>
              <label htmlFor='' className='form-label mt-3 fs-6 fw-bolder text-dark col-sm-3'>
                Current Litigation Amount
              </label>
              <div className='col-sm-9'>
                <NumberFormat
                  name='currentLitigationAmount'
                  value={formik.values.currentLitigationAmount}
                  className='form-control form-control-lg form-control-solid'
                  thousandSeparator={true}
                  prefix={'SG$ '}
                  onValueChange={(values: any) => {
                    const { value } = values
                    formik.setFieldValue('currentLitigationAmount', value)
                  }}
                />
                {/* {formik.touched.currentLitigationAmount && formik.errors.currentLitigationAmount && (
                  <div className='text-danger mt-2'>{formik.errors.currentLitigationAmount}</div>
                )} */}
              </div>
            </div>
          </>
        )}

        <div className='d-flex flex-end'>
          <button type='submit' className='btn btn-lg btn-primary me-3'>
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export { Step1Sub3, industryOptions, loanPurpose }
