import { ServerError, useMutation } from '@apollo/client'
import axios from 'axios'
import { useFormik } from 'formik'
import { FC, Fragment, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PhoneInput from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CustomLoaderModal } from '../../../../_metronic/helpers/components/CustomLoaderModal'
import Alert from '../../../../_metronic/partials/alert'
import { fetchStartWithForward, fetchStop } from '../../../../redux/actions/common'
import { getIntegrationURLFromENV } from '../../../../utils/getIntegrationURLFromENV'
import mutations from './../../../../setup/graphql/mutations'
import { AlexiRoutes } from '../../../../utils/constants/AlexiRoutes.enum'

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// const dateObj = (d: any): any => {
//   return {
//     year: Number(d.getFullYear()),
//     month: Number(d.getMonth() + 1),
//     day: Number(d.getDate()),
//     hour: Number(d.getHours()),
//     minute: Number(d.getMinutes()),
//   }
// }

const loanType = localStorage.getItem('loanType')

const Step4: FC = ({
  // prevStep,
  // nextStep,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [lastPickedDateError, setLastPickedDateError] = useState<boolean>(false)

  const decisionMaker = useSelector((state: any) => state.kycApplication.decisionMaker)
  const directors = useSelector((state: any) => state.kycApplication.directors)
  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const loanEligibility = useSelector((state: any) => state.kycApplication.loanEligibility)
  const singPassInfo = useSelector((state: any) => state.kycApplication.singPass)
  // const [bankStatement, setBankStatement] = useState<any>([])
  // const [incorrectFileType, setIncorrectFileType] = useState<boolean>(false)
  const [createPhoneDiscussion, onSuccessCreatePhoneDiscussion] = useMutation(
    mutations.CREATE_PHONE_DISSCUSSION
  )
  const [resendGuarantorEmail, onSuccessResendGuarantorEmail] = useMutation(
    mutations.RESEND_GUARANTOR_EMAIL
  )

  const { phoneDiscussion, applicationStatus } = loanApplication

  // dateOfDiscussion: phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,

  useEffect(() => {
    if (onSuccessCreatePhoneDiscussion.called && !onSuccessCreatePhoneDiscussion.loading) {
      // dispatch(fetchStop())
      if (onSuccessCreatePhoneDiscussion.data) {
        updateApplication({
          variables: {
            data: {
              applicationCompleteStep: 5,
              applicationStatus: 'submitted',
              bankName: formik?.values?.bankName,
              bankAccountHolder: formik?.values?.bankAccountHolder,
              bankAccountNumber: formik?.values?.bankAccountNo,
              bankSwiftCode: formik?.values?.bankSwiftCode,
            },
            where: { id: loanApplication.id },
          },
        })
        setTimeout(() => {
          history.push(`/${AlexiRoutes.SG_ONBOARDING}/completed/${loanApplication?.id}`)
        }, 1000)
      } else {
      }

      if (onSuccessCreatePhoneDiscussion.error) {
        const { networkError } = onSuccessCreatePhoneDiscussion.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessCreatePhoneDiscussion.loading])

  useEffect(() => {
    if (onSuccessResendGuarantorEmail.called && !onSuccessResendGuarantorEmail.loading) {
      dispatch(fetchStop())
      if (onSuccessResendGuarantorEmail.data) {
        if (onSuccessResendGuarantorEmail.data.resendGuarantorEmail) {
          setShowAlert(true)
          setAlertType('success')
          setAlertMsg('Email sent successfully')
        } else {
          setShowAlert(true)
          setAlertType('primary')
          setAlertMsg('Email could not be sent')
        }
        onSuccessResendGuarantorEmail.reset()
      }

      if (onSuccessResendGuarantorEmail.error) {
        const { networkError } = onSuccessResendGuarantorEmail.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        dispatch(fetchStop())
      }
    }
  }, [onSuccessResendGuarantorEmail.loading])

  const initialValues = {
    bankName: '',
    bankAccountHolder: '',
    bankAccountNo: '',
    retypeBankAccountNo: '',
    bankSwiftCode: '',
    // bankStatement: '',
    applicantName:
      phoneDiscussion.length > 0 ? phoneDiscussion[0].applicantName : decisionMaker?.personName,
    applicantNumber:
      phoneDiscussion.length > 0
        ? phoneDiscussion[0].applicantNumber
        : decisionMaker?.corppasMobileNumber,
    dateOfDiscussion:
      phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,
    confirmCheckbox: false,
  }

  const validationSchema = Yup.object().shape({
    bankName: Yup.string()
      .required('Bank Name required')
      .matches(/^(?:(?=^.*([a-z]|[A-Z]))(([a-z]|[A-Z]))|([a-z]|[A-Z]))/g, {
        message: 'Please enter valid Bank Name',
      }),
    bankAccountHolder: Yup.string()
      .required('Bank Account Holder required')
      .matches(/^(?:(?=^.*([a-z]|[A-Z]))(([a-z]|[A-Z]))|([a-z]|[A-Z]))/g, {
        message: 'Please enter valid Bank Account Holder',
      }),
    bankAccountNo: Yup.string()
      .required('Bank Account Number required')
      .matches(/^[0-9]*[^\s]$/, 'Enter Valid Bank Account Number'),
    retypeBankAccountNo: Yup.string()
      .required('Retype Account No required')
      .matches(/^[0-9]*[^\s]$/, 'Enter Valid Bank Account Number')
      .test('account-no-match', 'Account Numbers do not match', function (value) {
        return value === this.parent.bankAccountNo
      }),
    bankSwiftCode: Yup.string()
      .required('Bank Swift Code is required.')
      .test(
        'len',
        'Please enter valid swift code having 8 OR 11 characters.',
        (val) => val?.length === 8 || val?.length === 11
      )
      .matches(/^[A-Za-z0-9]+$/, {
        message: 'Please enter valid bank swift code.',
      }),
    // bankStatement: Yup.string().required('Bank Statement is requred'),
    applicantName: Yup.string().required('Applicant Name required'),
    applicantNumber: Yup.string().required('Applicant Number required'),
    dateOfDiscussion: Yup.string()
      .required('Appointment Date and Time required')
      .typeError('Appointment Date and Time required'),
    confirmCheckbox: Yup.bool().oneOf([true], 'Please check checkbox to move forward'),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (lastPickedDateError) {
        formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
        return
      }

      const { applicantName, applicantNumber, dateOfDiscussion } = values
      // dispatch(fetchStartWithForward())
      createPhoneDiscussion({
        variables: {
          data: {
            applicantName,
            applicantNumber,
            dateOfDiscussion,
            application: {
              connect: {
                id: loanApplication.id,
              },
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (lastPickedDateError) {
      formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
    }
  }, [formik.errors])

  const sendEmail = (id: string) => {
    dispatch(fetchStartWithForward())
    resendGuarantorEmail({
      variables: {
        shareholderId: id,
      },
    })
  }

  // const handleFileUpload = (event: any, type: string, fileType: string = 'application/pdf') => {
  //   event.preventDefault()

  //   if (event.target.validity.valid) {
  //     let files = []
  //     let uploadDocs = []

  //     const isValid = isValidFile(event, fileType)

  //     if (isValid) {
  //       for (let file of event.target.files) {
  //         file.filename = file.name
  //         files.push({ type: type, file })
  //         uploadDocs.push({
  //           application: {
  //             connect: {
  //               id: loanApplication.id,
  //             },
  //           },
  //           type: type,
  //           documentFile: {
  //             upload: file,
  //           },
  //         })
  //       }

  //       setIncorrectFileType(false)
  //       formik.setFieldValue('bankStatement', event?.target?.files[0])

  //       setBankStatement(uploadDocs)
  //     }
  //   }
  // }

  // const isValidFile = (event: any, type: string) => {
  //   const checkType = [...event.target.files].some((file: any) => file.type !== type)
  //   if (checkType) {
  //     setIncorrectFileType(true)
  //     return false
  //   }
  //   return true
  // }

  return (
    <div className='d-flex flex-column  flex-column-fluid w-100'>
      <h2>Review Application</h2>
      <div className='sub-heading text-muted mb-10'>
        Please review your application, this will be the last time you can review it before
        submitting it.{' '}
      </div>

      <h3>Shareholder Authentication Status</h3>
      <div className='sub-heading text-muted mb-10'>
        Before submitting all shareholders mentioned below should complete their eKYC. A link has
        been sent to their email address.
      </div>

      <div className=' row g-5 g-xl-8'>
        {directors &&
          directors.map((o: any, i: number) => {
            return (
              <div className='col-md-4' key={i}>
                <div className='card card-xl-stretch mb-xl-8 flex-center border border-2 border-gray-300 p-6'>
                  <div className='symbol symbol-35px symbol-circle mb-5'>
                    <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>{`${(o.personName.split(
                      ' '
                    ).length > 1
                      ? o.personName.split(' ')[0][0] +
                        o.personName.split(' ')[o.personName.split(' ').length - 1][0]
                      : o.personName.split(' ')[0][0]
                    ).toUpperCase()}`}</span>
                  </div>
                  <div className='fs-3 fw-bolder text-dark'>{o.personName}</div>
                  <div className='text-gray-400 fw-bold fs-5 mt-1 mb-2'>{o.corppasEmail}</div>
                  <div className='text-gray-400 fw-bold fs-5 mb-10'>{o.corppasMobileNumber}</div>
                  <div className='badge badge-secondary py-3 px-3 mb-10'>
                    <i className='fa fa-info text-gray'></i> Director
                  </div>
                  <div className='mb-10'>
                    {/* <div className="badge badge-light fw-bold py-3 px-3">Edit</div> */}
                    {o.kycStatus === 'pending' && (
                      <div
                        className='badge badge-light fw-bold py-3 px-3 cursor-pointer'
                        onClick={(e) => sendEmail(o.id)}
                      >
                        Resend
                      </div>
                    )}
                  </div>
                  <div
                    className={`badge fw-bold py-3 px-3 ${
                      o.kycStatus === 'pending' ? 'badge-light' : 'badge-primary'
                    }`}
                  >
                    {o.kycStatus === 'pending' ? (
                      <i className='fa fa-hourglass text-gray'></i>
                    ) : (
                      <i className='fa fa-check text-white'></i>
                    )}{' '}
                    {o.kycStatus}
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='mt-10'>
          <h3>Provide Bank Account</h3>
          <div className='sub-heading text-muted mb-10'>
            Please help us with your bank account details. The same bank account will be used to
            disburse the funds upon approval
          </div>
          <div className='row mb-10 mt-10'>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Bank Name
                </label>
                <div className='col-sm-8'>
                  <input
                    name='bankName'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.bankName && formik.errors.bankName && (
                    <div className='text-danger mt-2'>{formik.errors.bankName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Account Holder
                </label>
                <div className='col-sm-8'>
                  <input
                    name='bankAccountHolder'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.bankAccountHolder}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.bankAccountHolder && formik.errors.bankAccountHolder && (
                    <div className='text-danger mt-2'>{formik.errors.bankAccountHolder}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-10 mt-10'>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Account No
                </label>
                <div className='col-sm-8'>
                  <input
                    name='bankAccountNo'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.bankAccountNo}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.bankAccountNo && formik.errors.bankAccountNo && (
                    <div className='text-danger mt-2'>{formik.errors.bankAccountNo}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Retype Account No
                </label>
                <div className='col-sm-8'>
                  <input
                    name='retypeBankAccountNo'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.retypeBankAccountNo}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.retypeBankAccountNo && formik.errors.retypeBankAccountNo && (
                    <div className='text-danger mt-2'>{formik.errors.retypeBankAccountNo}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-10 mt-10'>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Swift Code
                </label>
                <div className='col-sm-8'>
                  <input
                    name='bankSwiftCode'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.bankSwiftCode}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.bankSwiftCode && formik.errors.bankSwiftCode && (
                    <div className='text-danger mt-2'>{formik.errors.bankSwiftCode}</div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className='col-sm-6'>
              <div className='row'>
                <label htmlFor='' className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3'>
                  Upload Bank Statement
                </label>
                <div className='col-sm-8'>
                  <input
                    id='upload-file-bank'
                    name='bankStatement'
                    type='file'
                    accept='application/pdf'
                    className='form-control form-control-lg form-control-solid'
                    onChange={(e: any) => handleFileUpload(e, 'bank-statement')}
                    // disabled
                  />
                  <div className='mt-2'>
                    Upload previous month bank statement of the same account mentioned above
                  </div>
                  {incorrectFileType ? (
                    <div className='text-danger mt-2'>Please upload pdf files only!</div>
                  ) : (
                    formik.touched.bankStatement &&
                    formik.errors.bankStatement && (
                      <div className='text-danger mt-2'>{formik.errors.bankStatement}</div>
                    )
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className='mt-10'>
          <h3>Phone Discussion</h3>

          <div className='row mb-10 mt-10'>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Applicant Name
                </label>
                <div className='col-sm-8'>
                  <input
                    name='applicantName'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.applicantName}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.applicantName && formik.errors.applicantName && (
                    <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Applicant Number
                </label>
                <div className='col-sm-8'>
                  <PhoneInput
                    id='applicantNumber'
                    international
                    defaultCountry='SG'
                    // countryCallingCodeEditable={false}
                    value={formik.values.applicantNumber}
                    onChange={(value) => formik.setFieldValue('applicantNumber', value)}
                    placeholder='Enter phone number'
                    className='int-phone-input'
                    // readOnly
                  />
                  {formik.errors.applicantNumber && (
                    <div className='text-danger mt-2'>{formik.errors.applicantNumber}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-sm-6'>
              <div className='row'>
                <label
                  htmlFor=''
                  className='form-label fs-6 fw-bolder text-dark col-sm-4 mt-3 required'
                >
                  Appointment Date/Time
                </label>
                <div className='col-sm-8'>
                  <DatePicker
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    dropdownMode='select'
                    scrollableMonthYearDropdown
                    showMonthDropdown
                    showTimeSelect
                    dateFormat={'MM/dd/yyyy, h:mm aa'}
                    locale='en'
                    required={true}
                    minDate={tomorrow}
                    selected={formik.values.dateOfDiscussion}
                    onChange={(value: any) => {
                      formik.setFieldValue('dateOfIncorporation', value)
                    }}
                  />
                  {formik.touched.dateOfDiscussion && formik.errors.dateOfDiscussion && (
                    <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
                  )}

                  <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className='mb-10 mt-10'
          style={{
            backgroundColor: 'rgba(253, 203, 110, 0.2)',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            border: '1px dashed gray',
            borderRadius: '5px',
            flexDirection: 'column',
          }}
        >
          <div style={{ fontSize: '18px', fontWeight: '700' }}>
            You are about to submit your application
          </div>
          <div style={{ fontSize: '14px', fontWeight: '500', color: '#7E8299' }}>
            By clicking this I agree that all the above information is correct and can be used for
            underwriting [Update the copy at finalization]
          </div>
        </div> */}

        {directors.length > 0 && directors.some((o: any) => o.kycStatus === 'pending') && (
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <div className='fs-6 text-gray-600'>
                Thank you for providing us with the relevant information needed to complete the
                company's loan application. You will be able to submit the application as soon as
                the shareholders completes their eKYC.
                <div className='text-black'>
                  Note: If shareholders have completed their eKYC then kindly refresh the page to
                  update the status
                </div>
              </div>
            </div>
          </div>
        )}

        {applicationStatus === 'in-progress' &&
          directors.length > 0 &&
          !directors.some((o: any) => o.kycStatus === 'pending') && (
            <>
              <div className='mt-20'>
                <h3>Consent and Terms</h3>

                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-2x svg-icon-warning svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>
                        You are about to submit your application
                      </h4>
                      <div className='fs-6 text-gray-600'>
                        By clicking this I agree that all the above information is correct and can
                        be used for underwriting. Please find Terms and Condition
                        <a
                          href={toAbsoluteUrl('/files/Standard_Customer_T&Cs.pdf')}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {' '}
                          Here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-5'>
                <label className='form-check form-check-inline form-check-solid me-5'>
                  <input
                    name='confirmCheckbox'
                    type='checkbox'
                    className='form-check-input'
                    checked={formik.values.confirmCheckbox}
                    onChange={formik.handleChange}
                  />
                  <span className='fw-bold ps-2 fs-6'>Confirm Application Submission</span>
                </label>
                {formik.touched.confirmCheckbox && formik.errors.confirmCheckbox && (
                  <div className='text-danger mt-2'>{formik.errors.confirmCheckbox}</div>
                )}
              </div>
            </>
          )}

        <div className='d-flex flex-end'>
          <button
            type='submit'
            className='btn btn-lg btn-primary me-3'
            disabled={
              applicationStatus === 'submitted' ||
              directors.some((o: any) => o.kycStatus === 'pending')
            }
          >
            Submit
          </button>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your document for signing, it might take a while.'
      />
    </div>
  )
}

export { Step4 }
