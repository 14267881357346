import { ServerError, useMutation } from '@apollo/client'
import axios from 'axios'
import { useFormik } from 'formik'
import { FC, Fragment, useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import PhoneInput from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { CustomLoaderModal } from '../../../../_metronic/helpers/components/CustomLoaderModal'
import Alert from '../../../../_metronic/partials/alert'
import { fetchStartWithForward, fetchStop } from '../../../../redux/actions/common'
import { getIntegrationURLFromENV } from '../../../../utils/getIntegrationURLFromENV'
import mutations from './../../../../setup/graphql/mutations'
import {
  appointmentHead,
  capitalHead,
  corppassHead,
  financialHead,
  historyHead,
  shareholderHead,
} from './Step3'
import { AlexiApplicationTypes } from '../../../../utils/constants/AlexiApplicationType.enum'

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// const dateObj = (d: any): any => {
//   return {
//     year: Number(d.getFullYear()),
//     month: Number(d.getMonth() + 1),
//     day: Number(d.getDate()),
//     hour: Number(d.getHours()),
//     minute: Number(d.getMinutes()),
//   }
// }

const loanType = localStorage.getItem('loanType')

const Step4: FC = ({
  // prevStep,
  // nextStep,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  const history: any = useHistory()

  const [openCustomLoaderModal, setOpenCustomLoaderModal] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>('')
  const [alertType, setAlertType] = useState<any>('primary')
  const [lastPickedDate, setLastPickedDate] = useState<any>(null)
  const [lastPickedDateError, setLastPickedDateError] = useState<boolean>(false)
  const [lastPickedTime, setLastPickedTime] = useState<any>(null)

  const guarantor = useSelector((state: any) => state.loan.guarantor)
  const decisionMaker = useSelector((state: any) => state.loan.decisionMaker)
  const directors = useSelector((state: any) => state.loan.directors)
  const loanApplication = useSelector((state: any) => state.loan.loanApplication)
  const loanEligibility = useSelector((state: any) => state.loan.loanEligibility)
  const singPassInfo = useSelector((state: any) => state.loan.singPass)

  const [createPhoneDiscussion, onSuccessCreatePhoneDiscussion] = useMutation(
    mutations.CREATE_PHONE_DISSCUSSION
  )
  const [resendGuarantorEmail, onSuccessResendGuarantorEmail] = useMutation(
    mutations.RESEND_GUARANTOR_EMAIL
  )

  const { phoneDiscussion, applicationStatus } = loanApplication

  // dateOfDiscussion: phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,

  useEffect(() => {
    if (onSuccessCreatePhoneDiscussion.called && !onSuccessCreatePhoneDiscussion.loading) {
      // dispatch(fetchStop())
      if (onSuccessCreatePhoneDiscussion.data) {
        updateApplication({
          variables: {
            data: { applicationCompleteStep: 5, applicationStatus: 'submitted' },
            where: { id: loanApplication.id },
          },
        })

        setTimeout(() => {
          getDocuSignReport()
        }, 1000)

        setTimeout(() => {
          history.push(`/application/completed/${loanApplication?.id}`)
        }, 1000)
      } else {
      }

      if (onSuccessCreatePhoneDiscussion.error) {
        const { networkError } = onSuccessCreatePhoneDiscussion.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        setAlertType('primary')
        dispatch(fetchStop())
      }
    }
  }, [onSuccessCreatePhoneDiscussion.loading])

  const editShareholders = () => {
    history.push(`/application/${loanApplication.id}?step=4`)
  }
  const getDocuSignReport = async () => {
    try {
      const env = localStorage.getItem('env') as string
      const API_URL = getIntegrationURLFromENV(env)

      const payload = {
        applicationId: loanApplication?.id,
        email: loanEligibility?.businessEmailAddress,
        name: loanApplication?.applicantName,
        redirectURL: window.location.origin + `/application/completed/${loanApplication?.id}`,
        pageNumber: 13,
      }
      setOpenCustomLoaderModal(true)
      const response = await axios.post(
        API_URL + 'automation/alexi/applications/docusignreport',
        payload
      )
      if (response.status === 201) {
        setOpenCustomLoaderModal(false)
        updateApplication({
          variables: {
            data: {
              envelopeId: response?.data?.envelopeId,
            },
            where: { id: loanApplication.id },
          },
        })

        window.location = response?.data?.redirectUrl
      }
    } catch (error) {
      console.log('error:', error)
      setShowAlert(true)
      setAlertMsg('Error in processing your application')
      setAlertType('primary')
      dispatch(fetchStop())
      setOpenCustomLoaderModal(false)
    }
  }

  useEffect(() => {
    if (onSuccessResendGuarantorEmail.called && !onSuccessResendGuarantorEmail.loading) {
      dispatch(fetchStop())
      if (onSuccessResendGuarantorEmail.data) {
        if (onSuccessResendGuarantorEmail.data.resendGuarantorEmail) {
          setShowAlert(true)
          setAlertType('success')
          setAlertMsg('Email sent successfully')
        } else {
          setShowAlert(true)
          setAlertType('primary')
          setAlertMsg('Email could not be sent')
        }
        onSuccessResendGuarantorEmail.reset()
      }

      if (onSuccessResendGuarantorEmail.error) {
        const { networkError } = onSuccessResendGuarantorEmail.error
        let errorList = 'ERROR: '
        if (networkError && (networkError as ServerError)) {
          errorList =
            errorList +
            (networkError as ServerError as any).result.errors
              .map((error: any) => error.message)
              .join(', ')
        }
        setShowAlert(true)
        setAlertMsg(errorList)
        dispatch(fetchStop())
      }
    }
  }, [onSuccessResendGuarantorEmail.loading])

  const initialValues = {
    applicantName:
      phoneDiscussion.length > 0 ? phoneDiscussion[0].applicantName : decisionMaker?.personName,
    applicantNumber:
      phoneDiscussion.length > 0
        ? phoneDiscussion[0].applicantNumber
        : decisionMaker?.corppasMobileNumber,
    dateOfDiscussion:
      phoneDiscussion.length > 0 ? new Date(phoneDiscussion[0].dateOfDiscussion) : tomorrow,
    confirmCheckbox: false,
  }

  const validationSchema = Yup.object().shape({
    applicantName: Yup.string().required('Applicant Name required'),
    applicantNumber: Yup.string().required('Applicant Number required'),
    dateOfDiscussion: Yup.string()
      .required('Appointment Date and Time required')
      .typeError('Appointment Date and Time required'),
    confirmCheckbox: Yup.bool().oneOf([true], 'Please check checkbox to move forward'),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (lastPickedDateError) {
        formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
        return
      }

      const { applicantName, applicantNumber, dateOfDiscussion } = values
      // dispatch(fetchStartWithForward())
      createPhoneDiscussion({
        variables: {
          data: {
            applicantName,
            applicantNumber,
            dateOfDiscussion,
            application: {
              connect: {
                id: loanApplication.id,
              },
            },
          },
        },
      })
    },
  })

  useEffect(() => {
    if (lastPickedDateError) {
      formik.setFieldError('dateOfDiscussion', 'Please select valid Date')
    }
  }, [formik.errors])

  const sendEmail = (id: string) => {
    dispatch(fetchStartWithForward())
    resendGuarantorEmail({
      variables: {
        shareholderId: id,
      },
    })
  }

  return (
    <div className='d-flex flex-column  flex-column-fluid w-100'>
      <h2>Review Application</h2>
      <div className='sub-heading text-muted mb-10'>
        Please review your application, this will be the last time you can review it before
        submitting it.{' '}
      </div>

      <h3>Shareholder Authentication Status</h3>
      <div className='sub-heading text-muted mb-10'>
        Before submitting all shareholders mentioned below should complete their eKYC. A link has
        been sent to their email address.
      </div>

      <div className=' row g-5 g-xl-8'>
        {guarantor &&
          guarantor.map((o: any, i: number) => {
            return (
              <div className='col-md-4' key={i}>
                <div className='card card-xl-stretch mb-xl-8 flex-center border border-2 border-gray-300 p-6'>
                  <div className='symbol symbol-35px symbol-circle mb-5'>
                    <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>{`${(o.personName.split(
                      ' '
                    ).length > 1
                      ? o.personName.split(' ')[0][0] +
                        o.personName.split(' ')[o.personName.split(' ').length - 1][0]
                      : o.personName.split(' ')[0][0]
                    ).toUpperCase()}`}</span>
                  </div>
                  <div className='fs-3 fw-bolder text-dark'>{o.personName}</div>
                  <div className='text-gray-400 fw-bold fs-5 mt-1 mb-2'>{o.corppasEmail}</div>
                  <div className='text-gray-400 fw-bold fs-5 mb-10'>{o.corppasMobileNumber}</div>
                  <div className='badge badge-secondary py-3 px-3 mb-10'>
                    <i className='fa fa-info text-gray'></i> Guarantor
                    {o.isDirector ? ', Director' : ''}
                  </div>
                  <div className='mb-10'>
                    {/* <div className="badge badge-light fw-bold py-3 px-3">Edit</div> */}
                    {o.kycStatus === 'pending' && (
                      <div
                        className='badge badge-light fw-bold py-3 px-3 cursor-pointer'
                        onClick={(e) => sendEmail(o.id)}
                      >
                        Resend
                      </div>
                    )}
                  </div>
                  <div
                    className={`badge fw-bold py-3 px-3 ${
                      o.kycStatus === 'pending' ? 'badge-light' : 'badge-primary'
                    }`}
                  >
                    {o.kycStatus === 'pending' ? (
                      <i className='fa fa-hourglass text-gray'></i>
                    ) : (
                      <i className='fa fa-check text-white'></i>
                    )}{' '}
                    {o.kycStatus}
                  </div>
                </div>
              </div>
            )
          })}
        {directors &&
          directors.map((o: any, i: number) => {
            return (
              !o.isGuarantor && (
                <div className='col-md-4' key={i}>
                  <div className='card card-xl-stretch mb-xl-8 flex-center border border-2 border-gray-300 p-6'>
                    <div className='symbol symbol-35px symbol-circle mb-5'>
                      <span className='symbol-label bg-secondary text-inverse-secondary fw-bolder'>{`${(o.personName.split(
                        ' '
                      ).length > 1
                        ? o.personName.split(' ')[0][0] +
                          o.personName.split(' ')[o.personName.split(' ').length - 1][0]
                        : o.personName.split(' ')[0][0]
                      ).toUpperCase()}`}</span>
                    </div>
                    <div className='fs-3 fw-bolder text-dark'>{o.personName}</div>
                    <div className='text-gray-400 fw-bold fs-5 mt-1 mb-2'>{o.corppasEmail}</div>
                    <div className='text-gray-400 fw-bold fs-5 mb-10'>{o.corppasMobileNumber}</div>
                    <div className='badge badge-secondary py-3 px-3 mb-10'>
                      <i className='fa fa-info text-gray'></i> Director
                    </div>
                    <div className='mb-10'>
                      {/* <div className="badge badge-light fw-bold py-3 px-3">Edit</div> */}
                      {o.kycStatus === 'pending' && (
                        <div
                          className='badge badge-light fw-bold py-3 px-3 cursor-pointer'
                          onClick={(e) => sendEmail(o.id)}
                        >
                          Resend
                        </div>
                      )}
                    </div>
                    <div
                      className={`badge fw-bold py-3 px-3 ${
                        o.kycStatus === 'pending' ? 'badge-light' : 'badge-primary'
                      }`}
                    >
                      {o.kycStatus === 'pending' ? (
                        <i className='fa fa-hourglass text-gray'></i>
                      ) : (
                        <i className='fa fa-check text-white'></i>
                      )}{' '}
                      {o.kycStatus}
                    </div>
                  </div>
                </div>
              )
            )
          })}
      </div>

      <div className='mt-5'>
        <h3>Review Application Form</h3>

        <div className='d-flex flex-column'>
          <Accordion defaultActiveKey='-1' className='custom-accordion'>
            <Accordion.Item eventKey='0'>
              <Accordion.Header className='fs-2'>
                <i className='fa fa-plus-square me-4'></i> Loan Application
              </Accordion.Header>
              <Accordion.Body>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Requested Amount</div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanApplication?.amountRequested
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Requested Duration</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.durationInMonths}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Company UEN</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.companyUEN}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Position within company</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.positionInCompany}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Applicant Name</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.applicantName}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Mobile Number</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.mobileNumber}</div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <i className='fa fa-plus-square me-4'></i> Loan Eligibility
              </Accordion.Header>
              <Accordion.Body>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Loan Purpose</div>
                  <div className='col-sm-4 fs-5'>{loanEligibility?.loanPurpose}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Requested Amount</div>
                  <div className='col-sm-4 fs-5'>
                    {loanApplication?.amountRequested
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Requested Duration</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.durationInMonths}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Company UEN</div>
                  <div className='col-sm-4 fs-5'>{loanApplication?.companyUEN}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Company Name</div>
                  <div className='col-sm-4 fs-5'>{loanEligibility?.companyName}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Business Mobile Number</div>
                  <div className='col-sm-4 fs-5'>{loanEligibility?.businessMobileNumber}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Business Email Address</div>
                  <div className='col-sm-4 fs-5'>{loanEligibility?.businessEmailAddress}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Date of Incorporation</div>
                  <div className='col-sm-4 fs-5'>
                    {loanEligibility?.dateOfIncorporation
                      ? loanEligibility?.dateOfIncorporation.split('T')[0]
                      : ''}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Industry</div>
                  <div className='col-sm-4 fs-5'>{loanEligibility?.industryType}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Turnover</div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.turnover
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Net Operating Income</div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.profit
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Any Outstanding Loans/Debts</div>
                  <div className='col-sm-4 fs-5'>
                    {loanEligibility?.anyOutstandingDebts ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Total Monthly Installments to banks</div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.totalMonthlyInstallmentsToBanks
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>
                    Total Monthly Installments to non-banks
                  </div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.totalMonthlyInstallmentsToNonBanks
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>
                    Total Revolving Credit outstanding to banks
                  </div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.totalRevolvingCreditOutstandingToBanks
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>
                    Total Revolving Credit outstanding to non-banks
                  </div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.totalRevolvingCreditOutstandingToNonBanks
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Current Litigation Amount</div>
                  <div className='col-sm-4 fs-5'>
                    SGD${' '}
                    {loanEligibility?.currentLitigationAmount
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='2'>
              <Accordion.Header>
                <i className='fa fa-plus-square me-4'></i> Income Details
              </Accordion.Header>
              <Accordion.Body>
                {loanApplication.incomeDocuments.length > 0 && (
                  <>
                    <h2>Bank Statements</h2>
                    {loanApplication.incomeDocuments.filter(
                      ({ type }: any) => type === 'bank-statement'
                    ).length > 0 ? (
                      <>
                        {loanApplication.incomeDocuments
                          .filter(({ type }: any) => type === 'bank-statement')
                          .map(({ documentFile }: any, i: number) => {
                            const filename = documentFile.filename.split('.')
                            // const name = filename[0].substring(0,15);
                            const name = filename[0]
                            const extension = filename[1]
                            return (
                              <div key={i} className='d-flex justify-content-between'>
                                {`${name}.${extension}`}
                              </div>
                            )
                          })}
                      </>
                    ) : (
                      <div>No files uploaded</div>
                    )}

                    <h2 className='mt-10'>GST Statement</h2>
                    {loanApplication.incomeDocuments.filter(
                      ({ type }: any) => type === 'gst-statement'
                    ).length > 0 ? (
                      <>
                        {loanApplication.incomeDocuments
                          .filter(({ type }: any) => type === 'gst-statement')
                          .map(({ documentFile }: any, i: number) => {
                            const filename = documentFile.filename.split('.')
                            // const name = filename[0].substring(0,15);
                            const name = filename[0]
                            const extension = filename[1]
                            return (
                              <div key={i} className='d-flex justify-content-between'>
                                {`${name}.${extension}`}
                              </div>
                            )
                          })}
                      </>
                    ) : (
                      <div>No files uploaded</div>
                    )}

                    {/* <h2 className="mt-10">Financial Statement</h2>
                    {loanApplication.incomeDocuments.filter(({ type }: any) => type === 'financial-statement').length > 0
                      ? <>
                        {loanApplication.incomeDocuments
                          .filter(({ type }: any) => type === 'financial-statement')
                          .map(({ documentFile }: any, i: number) => {
                            const filename = documentFile.filename.split('.');
                            // const name = filename[0].substring(0,15);
                            const name = filename[0];
                            const extension = filename[1];
                            return (
                              <div key={i} className="d-flex justify-content-between">
                                {`${name}.${extension}`}
                              </div>
                            )
                          })
                        }
                      </>
                      : <div>No files uploaded</div>
                    } */}
                    {loanType &&
                    loanType.toLowerCase() === AlexiApplicationTypes.RECURRING_APPLICATION ? (
                      <>
                        <h2 className='mt-10'>Revenue Details</h2>
                        {loanApplication.incomeDocuments.filter(
                          ({ type }: any) => type === 'revenue-financing-statement'
                        ).length > 0 ? (
                          <>
                            {loanApplication.incomeDocuments
                              .filter(({ type }: any) => type === 'revenue-financing-statement')
                              .map(({ documentFile }: any, i: number) => {
                                const filename = documentFile.filename.split('.')
                                // const name = filename[0].substring(0,15);
                                const name = filename[0]
                                const extension = filename[1]
                                return (
                                  <div key={i} className='d-flex justify-content-between'>
                                    {`${name}.${extension}`}
                                  </div>
                                )
                              })}
                          </>
                        ) : (
                          <div>No files uploaded</div>
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='3'>
              <Accordion.Header>
                <i className='fa fa-plus-square me-4'></i> Company Profile
              </Accordion.Header>
              <Accordion.Body>
                <h2>Company Profile</h2>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold fw-bold'>UINFIN</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.uinfin || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Name</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.name || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Sex</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.sex || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Date of Birth</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.dob || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Mobile Number</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.mobileno || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Email</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.email || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Nationality</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.nationality || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Residential Status</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.residentialstatus || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Registered Address</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.regadd || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Owner Private</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.ownerprivate || 'N/A'}</div>
                </div>

                <h3 className='mt-10'>Notice of Assessment History</h3>
                <div className='table-responsive mb-10'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {singPassInfo.noahistory.length > 0 &&
                          [...historyHead, ...Object.keys(singPassInfo.noahistory[0])]
                            .reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            .map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'taxclearance') tableHead = 'tax-clearance'
                              else if (key === 'yearofassessment') tableHead = 'year-of-assessment'
                              else tableHead = key

                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {singPassInfo.noahistory.length > 0 &&
                        Object.values(singPassInfo.noahistory).map((list: any, i) => {
                          const headList: any = [
                            ...historyHead,
                            ...Object.keys(singPassInfo.noahistory[0]),
                          ].reduce(
                            (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                            []
                          )
                          return (
                            <tr key={i}>
                              {headList.map((head: any, i: number) => {
                                let value = `${list[head].value}`

                                if (
                                  head === 'amount' ||
                                  head === 'interest' ||
                                  head === 'yearofassessment' ||
                                  head === 'employment'
                                )
                                  value = value
                                    .toString()
                                    .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')
                                if (head === 'amount' || head === 'interest')
                                  value = 'SGD$ ' + value
                                else value = value.toString()
                                return <td key={i}>{value}</td>
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>

                <h2>Corporate Information</h2>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>UEN</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.uen || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Entity Name</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.entityName || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Entity Type</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.entityType || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Entity Status</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.entityStatus || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Registered Address</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.enregadd || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Registered Date</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.registrationDate || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Primary SSIC</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.primarySSIC || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Secondary SSIC</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.secondarySSIC || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Business Expiry Date</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.businessExpiryDate || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Country Of Incorporation</div>
                  <div className='col-sm-4 fs-5'>
                    {singPassInfo?.countryOfIncorporation || 'N/A'}
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Business Constitution</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.businessConstitution || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Ownership</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.ownership || 'N/A'}</div>
                </div>
                <div className='row'>
                  <div className='col-sm-4 fs-5 fw-bold'>Company Type</div>
                  <div className='col-sm-4 fs-5'>{singPassInfo?.companyType || 'N/A'}</div>
                </div>

                <h3 className='mt-10'>Financials</h3>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {singPassInfo.financials.length > 0 &&
                          [...financialHead, ...Object.keys(singPassInfo.financials[0])]
                            .reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            .map((tableHead: any, i: number) => {
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {singPassInfo.financials.length > 0 &&
                        Object.values(singPassInfo.financials).map((list: any, i) => {
                          const headList: any = [
                            ...financialHead,
                            ...Object.keys(singPassInfo.financials[0]),
                          ].reduce(
                            (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                            []
                          )
                          return (
                            <tr key={i}>
                              {headList.map((head: any, i: number) => {
                                let value: any
                                if (head === 'currency') value = `${list?.[head]?.desc ?? 'N/A'}`
                                else if (
                                  head === 'current-period-end-date' ||
                                  head === 'current-period-start-date'
                                )
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                else
                                  value = `${
                                    list?.[head]?.value
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? 'N/A'
                                  }`
                                return <td key={i}>{value || 'N/A'}</td>
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>

                  {singPassInfo.financials.length === 0 && (
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          {financialHead.map((tableHead: any, i: number) => {
                            const value = tableHead
                              .replace(/-/g, ' ')
                              .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                            return (
                              <th className='min-w-150px' key={i}>
                                {value}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {financialHead.map((key: string, i: number) => {
                            return (
                              <Fragment key={i}>
                                <td>N/A</td>
                              </Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>

                <h3 className='mt-10'>Corppass Contacts</h3>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {singPassInfo.corppassContacts &&
                          singPassInfo.corppassContacts.length > 0 &&
                          [...corppassHead, ...Object.keys(singPassInfo.corppassContacts[0])]
                            .reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            .map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                              else tableHead = key
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {singPassInfo.corppassContacts &&
                        singPassInfo.corppassContacts.length > 0 &&
                        Object.values(singPassInfo.corppassContacts).map((list: any, i) => {
                          const headList: any = [
                            ...corppassHead,
                            ...Object.keys(singPassInfo.corppassContacts[0]),
                          ].reduce(
                            (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                            []
                          )
                          return (
                            <tr key={i}>
                              {headList.map((head: any, i: number) => {
                                let value: any
                                if (head === 'person-reference') {
                                  value = (
                                    <div>
                                      <div>
                                        <span className='fw-bold'>IdNo: </span>
                                        {list?.[head]?.idno?.value ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Nationality: </span>
                                        {list?.[head]?.nationality?.desc ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Person name: </span>
                                        {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                      </div>
                                    </div>
                                  )
                                } else if (head === 'corppass-email')
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                else if (head === 'corppass-mobileno')
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                return <td key={i}>{value || 'N/A'}</td>
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>

                  {singPassInfo.corppassContacts.length === 0 && (
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          {corppassHead.map((key: any, i: number) => {
                            let tableHead = ''
                            if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                            else tableHead = key
                            const value = tableHead
                              .replace(/-/g, ' ')
                              .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                            return (
                              <th className='min-w-150px' key={i}>
                                {value}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {corppassHead.map((key: string, i: number) => {
                            return (
                              <Fragment key={i}>
                                <td>N/A</td>
                              </Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>

                <h3 className='mt-10'>Capitals</h3>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {singPassInfo.capitals &&
                          singPassInfo.capitals.length > 0 &&
                          [...capitalHead, ...Object.keys(singPassInfo.capitals[0])]
                            .reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            .map((tableHead: any, i: number) => {
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {singPassInfo.capitals &&
                        singPassInfo.capitals.length > 0 &&
                        Object.values(singPassInfo.capitals).map((list: any, i) => {
                          const headList: any = [
                            ...capitalHead,
                            ...Object.keys(singPassInfo.capitals[0]),
                          ].reduce(
                            (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                            []
                          )
                          return (
                            <tr key={i}>
                              {headList.map((head: any, i: number) => {
                                let value: any
                                if (
                                  head === 'capital-type' ||
                                  head === 'currency' ||
                                  head === 'currency'
                                )
                                  value = `${list?.[head]?.desc ?? 'N/A'}`
                                else if (
                                  head === 'issued-capital-amount' ||
                                  head === 'paid-up-capital-amount' ||
                                  head === 'share-allotted-amount'
                                )
                                  value = `SGD$ ${
                                    list?.[head]?.value
                                      .toString()
                                      .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,') ?? 'N/A'
                                  }`
                                return <td key={i}>{value || 'N/A'}</td>
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>

                  {singPassInfo.capitals.length === 0 && (
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          {capitalHead.map((tableHead: any, i: number) => {
                            const value = tableHead
                              .replace(/-/g, ' ')
                              .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                            return (
                              <th className='min-w-150px' key={i}>
                                {value}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {capitalHead.map((key: string, i: number) => {
                            return (
                              <Fragment key={i}>
                                <td>N/A</td>
                              </Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>

                <h3 className='mt-10'>Shareholders List</h3>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {singPassInfo.shareholdersList &&
                          singPassInfo.shareholdersList.length > 0 &&
                          [...shareholderHead, ...Object.keys(singPassInfo.shareholdersList[0])]
                            .reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            .map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                              else tableHead = key
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {singPassInfo.shareholdersList &&
                        singPassInfo.shareholdersList.length > 0 &&
                        Object.values(singPassInfo.shareholdersList).map((list: any, i) => {
                          const headList: any = [
                            ...shareholderHead,
                            ...Object.keys(singPassInfo.shareholdersList[0]),
                          ].reduce(
                            (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                            []
                          )
                          return (
                            <tr key={i}>
                              {headList.map((head: any, i: number) => {
                                let value: any
                                // if(head === 'person-reference') value = `IdNo: ${list[head].idno.value} Nationality: ${list[head].nationality.desc} Person name: ${list[head]['person-name'].value}`
                                if (head === 'person-reference') {
                                  value = (
                                    <div>
                                      <div>
                                        <span className='fw-bold'>IdNo: </span>
                                        {list?.[head]?.idno?.value ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Nationality: </span>
                                        {list?.[head]?.nationality?.desc ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Person name: </span>
                                        {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                      </div>
                                    </div>
                                  )
                                } else if (head === 'entity-reference') {
                                  value = (
                                    <div>
                                      <div>
                                        <span className='fw-bold'>Name: </span>
                                        {list?.[head]?.['entity-name']?.value ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Type: </span>
                                        {list?.[head]?.['entity-type']?.desc ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>UEN: </span>
                                        {list?.[head]?.uen?.value ?? 'N/A'}
                                      </div>
                                    </div>
                                  )
                                } else if (
                                  head === 'category' ||
                                  head === 'currency' ||
                                  head === 'share-type'
                                )
                                  value = `${list?.[head]?.desc ?? 'N/A'}`
                                else if (head === 'allocation')
                                  value = `SGD$  ${list?.[head]?.value
                                    .toString()
                                    .replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,')}`
                                else if (head === 'corppass-email' || head === 'corppass-mobileno')
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                return <td key={i}>{value}</td>
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>

                  {singPassInfo.shareholdersList.length === 0 && (
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          {shareholderHead.map((key: any, i: number) => {
                            let tableHead = ''
                            if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                            else tableHead = key
                            const value = tableHead
                              .replace(/-/g, ' ')
                              .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                            return (
                              <th className='min-w-150px' key={i}>
                                {value}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {shareholderHead.map((key: string, i: number) => {
                            return (
                              <Fragment key={i}>
                                <td>N/A</td>
                              </Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>

                <h3 className='mt-10'>Appointments</h3>
                <div className='table-responsive'>
                  <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        {singPassInfo.appointments &&
                          singPassInfo.appointments.length > 0 &&
                          [...appointmentHead, ...Object.keys(singPassInfo.appointments[0])]
                            .reduce(
                              (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                              []
                            )
                            .map((key: any, i: number) => {
                              let tableHead = ''
                              if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                              else tableHead = key
                              const value = tableHead
                                .replace(/-/g, ' ')
                                .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                              return (
                                <th className='min-w-150px' key={i}>
                                  {value}
                                </th>
                              )
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {singPassInfo.appointments &&
                        singPassInfo.appointments.length > 0 &&
                        Object.values(singPassInfo.appointments).map((list: any, i) => {
                          const headList: any = [
                            ...appointmentHead,
                            ...Object.keys(singPassInfo.appointments[0]),
                          ].reduce(
                            (acc: any, curr: any) => (acc.includes(curr) ? acc : [...acc, curr]),
                            []
                          )

                          return (
                            <tr key={i}>
                              {headList.map((head: any, i: number) => {
                                let value: any
                                // if(head === 'person-reference') value = `IdNo: ${list[head].idno.value} Nationality: ${list[head].nationality.desc} Person name: ${list[head]['person-name'].value}`
                                if (head === 'person-reference') {
                                  value = (
                                    <div>
                                      <div>
                                        <span className='fw-bold'>IdNo: </span>
                                        {list?.[head]?.idno?.value ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Nationality: </span>
                                        {list?.[head]?.nationality?.desc ?? 'N/A'}
                                      </div>
                                      <div>
                                        <span className='fw-bold'>Person name: </span>
                                        {list?.[head]?.['person-name']?.value ?? 'N/A'}
                                      </div>
                                    </div>
                                  )
                                } else if (head === 'position')
                                  value = `${list?.[head]?.desc ?? 'N/A'}`
                                else if (head === 'appointment-date')
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                else if (head === 'corppass-email')
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                else if (head === 'category')
                                  value = `${list?.[head]?.desc ?? 'N/A'}`
                                else if (head === 'corppass-mobileno')
                                  value = `${list?.[head]?.value ?? 'N/A'}`
                                else value = ''
                                return <td key={i}>{value || 'N/A'}</td>
                              })}
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>

                  {singPassInfo.appointments.length === 0 && (
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          {appointmentHead.map((key: any, i: number) => {
                            let tableHead = ''
                            if (key === 'corppass-mobileno') tableHead = 'mobile-number'
                            else tableHead = key
                            const value = tableHead
                              .replace(/-/g, ' ')
                              .replace(/(?: |\b)(\w)/g, (key: any, p1: any) => key.toUpperCase())
                            return (
                              <th className='min-w-150px' key={i}>
                                {value}
                              </th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {appointmentHead.map((key: string, i: number) => {
                            return (
                              <Fragment key={i}>
                                <td>N/A</td>
                              </Fragment>
                            )
                          })}
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='4'>
              <Accordion.Header className='fs-2'>
                <i className='fa fa-plus-square me-4'></i> Shareholders
              </Accordion.Header>
              <Accordion.Body>
                {/* <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <button className='btn btn-lg btn-primary me-3' onClick={() => editShareholders()}>Edit</button>
                  </div>
                </div> */}
                {loanApplication.shareholderKycStatus.length > 0 && (
                  <>
                    <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bolder text-muted'>
                          <th className='min-w-150px'>Name</th>
                          <th className='min-w-140px'>Allocation</th>
                          <th className='min-w-120px'>Decision Maker</th>
                          <th className='min-w-120px'>Guarantor</th>
                          <th className='min-w-120px'>Director</th>
                          <th className='min-w-120px'>Email Address</th>
                          <th className='min-w-120px'>Mobile No</th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...loanApplication.shareholderKycStatus]
                          .sort(({ allocation: a }: any, { allocation: b }: any) => b - a)
                          .map((o: any, i: number) => {
                            return (
                              <tr key={i}>
                                <td>{o.personName}</td>
                                <td>{o.allocation.toFixed(2)}%</td>
                                <td>
                                  {o.isDecisionMaker && (
                                    <input
                                      type='checkbox'
                                      className='form-check-input custom-checkbox'
                                      defaultChecked={o.isDecisionMaker}
                                      disabled
                                    />
                                  )}
                                </td>
                                <td>
                                  {o.isGuarantor && (
                                    <input
                                      type='checkbox'
                                      className='form-check-input custom-checkbox'
                                      defaultChecked={o.isGuarantor}
                                      disabled
                                    />
                                  )}
                                </td>
                                <td>
                                  {o.isDirector && (
                                    <input
                                      type='checkbox'
                                      className='form-check-input custom-checkbox'
                                      defaultChecked={o.isDirector}
                                      disabled
                                    />
                                  )}
                                </td>
                                <td>{o.corppasEmail}</td>
                                <td>{o.corppasMobileNumber}</td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='mt-10'>
          <h3>Phone Discussion</h3>

          <div className='row mb-10 mt-10'>
            <div className='col-sm-6'>
              <div className='row'>
                <label htmlFor='' className='form-label fs-6 fw-bolder text-dark col-sm-4'>
                  Applicant Name
                </label>
                <div className='col-sm-8'>
                  <input
                    name='applicantName'
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    value={formik.values.applicantName}
                    onChange={formik.handleChange}
                    // disabled
                  />
                  {formik.touched.applicantName && formik.errors.applicantName && (
                    <div className='text-danger mt-2'>{formik.errors.applicantName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='row'>
                <label htmlFor='' className='form-label fs-6 fw-bolder text-dark col-sm-4'>
                  Applicant Number
                </label>
                <div className='col-sm-8'>
                  <PhoneInput
                    id='applicantNumber'
                    international
                    defaultCountry='SG'
                    // countryCallingCodeEditable={false}
                    value={formik.values.applicantNumber}
                    onChange={(value) => formik.setFieldValue('applicantNumber', value)}
                    placeholder='Enter phone number'
                    className='int-phone-input'
                    // readOnly
                  />
                  {formik.touched.applicantNumber && formik.errors.applicantNumber && (
                    <div className='text-danger mt-2'>{formik.errors.applicantNumber}</div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-sm-6'>
              {/* <div className="row">
                <label htmlFor="" className="form-label fs-6 fw-bolder text-dark col-sm-4">Appointment Date/Time</label>
                <div className="col-sm-8">
                  <DateTimePicker 
                    required
                    name="dateOfDiscussion"
                    value={formik.values.dateOfDiscussion}
                    format={"dd/MM/yyyy hh:mm a"} 
                    minDate={new Date(new Date().getTime()+24*60*60*1000)}
                    className="datepicker"
                    onChange={(value:any) => {
                      // formik.setFieldValue('dateOfDiscussion', value);
                      var time = value.getHours() + ":" + value.getMinutes();
                      setLastPickedDate(value)
                      setLastPickedTime(time)
                    }}
                    onCalendarOpen={() => {
                      setLastPickedDate(null);
                      setLastPickedDateError(false);
                    }}
                    onCalendarClose={() => {
                        if(lastPickedDate >= tomorrow){
                          formik.setFieldValue('dateOfDiscussion', lastPickedDate);
                          setLastPickedDateError(false);
                        } else {
                          // formik.setFieldValue('dateOfDiscussion', tomorrow);
                          setLastPickedDateError(true);
                          formik.setFieldError('dateOfDiscussion', 'Please select valid Date');
                        }

                    }}
                    onClockOpen={() => {
                      setLastPickedTime(null);
                      setLastPickedDateError(false);
                      formik.setErrors({});
                    }}
                    onClockClose={() => {
                      console.log('close')
                      if(lastPickedTime){
                        const minutes = Number(lastPickedTime.split(':')[1]);
                        if(minutes > 60){
                          setLastPickedDateError(true);
                          formik.setFieldError('dateOfDiscussion', 'Please select valid Date');
                        }
                      }
                    }}
                  />

                  <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
                
                </div>
              </div> */}
              <div className='row'>
                <label htmlFor='' className='form-label fs-6 fw-bolder text-dark col-sm-4'>
                  Appointment Date/Time
                </label>
                <div className='col-sm-8'>
                  <DatePicker
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                    dropdownMode='select'
                    scrollableMonthYearDropdown
                    showMonthDropdown
                    showTimeSelect
                    dateFormat={'MM/dd/yyyy, h:mm aa'}
                    locale='en'
                    required={true}
                    minDate={tomorrow}
                    selected={formik.values.dateOfDiscussion}
                    onChange={(value: any) => {
                      formik.setFieldValue('dateOfIncorporation', value)
                    }}
                  />
                  {formik.touched.dateOfDiscussion && formik.errors.dateOfDiscussion && (
                    <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
                  )}

                  <div className='text-danger mt-2'>{formik.errors.dateOfDiscussion} &nbsp;</div>
                </div>
              </div>
            </div>
            {/* <div className="col-sm-6">
              <div className="row">
                <label htmlFor="" className="form-label fs-6 fw-bolder text-dark col-sm-4">Appointment Time</label>
                <div className="col-sm-8">
                  <input type="text" className="form-control form-control-lg form-control-solid"/>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {guarantor.length > 0 && guarantor.some((o: any) => o.kycStatus === 'pending') && (
          <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-bold'>
              <div className='fs-6 text-gray-600'>
                Thank you for providing us with the relevant information needed to complete the
                company's loan application. You will be able to submit the application as soon as
                the shareholders completes their eKYC.
                <div className='text-black'>
                  Note: If shareholders have completed their eKYC then kindly refresh the page to
                  update the status
                </div>
              </div>
            </div>
          </div>
        )}

        {applicationStatus === 'in-progress' &&
          guarantor.length > 0 &&
          !guarantor.some((o: any) => o.kycStatus === 'pending') && (
            <>
              <div className='mt-20'>
                <h3>Consent and Terms</h3>

                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className='svg-icon-2x svg-icon-warning svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    <div className='fw-bold'>
                      <h4 className='text-gray-800 fw-bolder'>
                        You are about to submit your application
                      </h4>
                      <div className='fs-6 text-gray-600'>
                        By clicking this I agree that all the above information is correct and can
                        be used for underwriting. Please find Terms and Condition
                        <a
                          href={toAbsoluteUrl('/files/Standard_Customer_T&Cs.pdf')}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {' '}
                          Here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-5'>
                <label className='form-check form-check-inline form-check-solid me-5'>
                  <input
                    name='confirmCheckbox'
                    type='checkbox'
                    className='form-check-input'
                    checked={formik.values.confirmCheckbox}
                    onChange={formik.handleChange}
                  />
                  <span className='fw-bold ps-2 fs-6'>Confirm Application Submission</span>
                </label>
                {formik.touched.confirmCheckbox && formik.errors.confirmCheckbox && (
                  <div className='text-danger mt-2'>{formik.errors.confirmCheckbox}</div>
                )}
              </div>
            </>
          )}

        <div className='d-flex flex-end'>
          <button
            type='submit'
            className='btn btn-lg btn-primary me-3'
            disabled={
              applicationStatus === 'submitted' ||
              guarantor.some((o: any) => o.kycStatus === 'pending') ||
              directors.some((o: any) => o.kycStatus === 'pending')
            }
          >
            Submit
          </button>
        </div>
      </form>

      {showAlert && (
        <Alert variant={alertType} showAlert={showAlert} setShowAlert={setShowAlert}>
          {alertMsg}
        </Alert>
      )}
      <CustomLoaderModal
        openCustomLoaderModal={openCustomLoaderModal}
        setOpenCustomLoaderModal={setOpenCustomLoaderModal}
        message='Please wait while we process your document for signing, it might take a while.'
      />
    </div>
  )
}

export { Step4 }
