import { useFormik } from 'formik'
import { FC } from 'react'
import NumberFormat from 'react-number-format'
import PhoneInput from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { convertToDate, trim } from '../../../../_metronic/helpers'
import { fetchStartWithForward } from '../../../../redux/actions/common'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import CompanyVerification from '../CompanyVerification'

const errorMsg = {
  required: 'Required',
  amountRange: 'Amount range should be between 75,000 - 250,000',
  durationRange: 'Duration range should be between 3 - 12 months',
}

const industryOptions = [
  { label: 'Service Sector – F&B', value: 'service-sector-fb', rating: 'H', pricing: '21' },
  { label: 'Service Sector – non-F&B', value: 'service-sector-non-fb', rating: 'M', pricing: '15' },
  { label: 'Health & Human Services', value: 'health-human-services', rating: 'M', pricing: '15' },
  { label: 'Education', value: 'education', rating: 'L', pricing: '8' },
  { label: 'IT services', value: 'it-services', rating: 'L', pricing: '8' },
  { label: 'Media', value: 'media', rating: 'M', pricing: '15' },
  {
    label: 'Agriculture / Fishing / Food Production',
    value: 'agriculture-fishing-foodproduction',
    rating: 'H',
    pricing: '21',
  },
  { label: 'Wholesale Trader', value: 'wholesale-trader', rating: 'M', pricing: '15' },
  { label: 'Retail Trading', value: 'retail-trading', rating: 'M', pricing: '15' },
  {
    label: 'Manufacturing – heavy industries',
    value: 'manufacturing-heavy-industries',
    rating: 'M',
    pricing: '15',
  },
  {
    label: 'Manufacturing – consumer related',
    value: 'manufacturing-consumer-related',
    rating: 'M',
    pricing: '15',
  },
  {
    label: 'Manufacturing – computer & electronics',
    value: 'manufacturing-computer-electronics',
    rating: 'L',
    pricing: '8',
  },
  {
    label: 'Energy – generation and distribution',
    value: 'energy-generation-distribution',
    rating: 'H',
    pricing: '21',
  },
  { label: 'Transport & Logistics', value: 'transport-logistics', rating: 'M', pricing: '15' },
  { label: 'Renewable', value: 'renewable', rating: 'M', pricing: '15' },
  {
    label: 'Building and Construction - Private Sector',
    value: 'building-construction-private',
    rating: 'H',
    pricing: '21',
  },
  {
    label: 'Building and Construction - Public Sector',
    value: 'building-construction-public',
    rating: 'L',
    pricing: '8',
  },
  { label: 'Other', value: 'other', rating: 'M', pricing: '15' },
]

const loanPurpose = [
  { label: 'Business Expansion', value: 'business-expansion' },
  { label: 'Pay Invoices', value: 'pay-invoices' },
  { label: 'Equipment Purchase', value: 'equipment-purchase' },
  { label: 'Infrastructure Upgrades', value: 'infrastructure-upgrades' },
  { label: 'Working Capital', value: 'working-capital' },
  { label: 'Other', value: 'other' },
]

const maxAmount = 500000
const minAmount = 75000
const maxDuration = 12
const minDuration = 3
const today = new Date()
// const fourDigitDate = new Date('1000');

// const dateObj = (d: any): any => {
//   return d
// }

const Step1Sub3: FC<any> = ({
  createLoanEligibility,
  updateLoanElgibility,
  updateApplication,
}: any) => {
  const dispatch = useDispatch()
  /* 
  const [date, setDate] = useState(null)
  const [lastPickedDate, setLastPickedDate] = useState<any>(null);
  const [lastPickedDateError, setLastPickedDateError] = useState<boolean>(false); */
  // const [initialDate, setInitialDate] = useState()

  const loanApplication = useSelector((state: any) => state.kycApplication.loanApplication)
  const loanEligibility = useSelector((state: any) => state.kycApplication.loanEligibility)

  // useEffect(() => {
  //   if (loanEligibility !== null) {
  //     const date = dateObj(new Date(loanEligibility?.dateOfIncorporation))
  //     setInitialDate(date)
  //   }
  // }, [loanEligibility])

  const initialValues = {
    loanPurpose: loanEligibility ? loanEligibility?.loanPurpose : '',
    amountRequested: loanApplication ? loanApplication.amountRequested : '',
    durationInMonths: loanApplication ? loanApplication.durationInMonths : '',
    companyUEN: loanApplication ? loanApplication.companyUEN : '',
    companyName: loanEligibility ? loanEligibility?.companyName : '',
    businessMobileNumber: loanEligibility
      ? loanEligibility?.businessMobileNumber
      : loanApplication?.mobileNumber,
    businessEmailAddress: loanEligibility ? loanEligibility?.businessEmailAddress : '',
    industryType: loanEligibility ? loanEligibility?.industryType : '',
    turnover: loanEligibility ? loanEligibility?.turnover : 0,
    profit: loanEligibility ? loanEligibility?.profit : 0,
    totalMonthlyInstallmentsToBanks: loanEligibility
      ? loanEligibility?.totalMonthlyInstallmentsToBanks
      : 0,
    totalMonthlyInstallmentsToNonBanks: loanEligibility
      ? loanEligibility?.totalMonthlyInstallmentsToNonBanks
      : 0,
    totalRevolvingCreditOutstandingToBanks: loanEligibility
      ? loanEligibility?.totalRevolvingCreditOutstandingToBanks
      : 0,
    totalRevolvingCreditOutstandingToNonBanks: loanEligibility
      ? loanEligibility?.totalRevolvingCreditOutstandingToNonBanks
      : 0,
    currentLitigationAmount: loanEligibility ? loanEligibility?.currentLitigationAmount : 0,
    anyOutstandingDebts: loanEligibility ? loanEligibility?.anyOutstandingDebts : false,
    otherType: loanEligibility ? loanEligibility?.otherType : '',
    dateOfIncorporation: loanEligibility
      ? new Date(loanEligibility?.dateOfIncorporation)
      : undefined,
  }

  const validationSchema = Yup.object().shape({
    // loanPurpose: Yup.string().required(errorMsg.required),
    loanPurpose: Yup.string()
      .required('Please select a Loan Purpose')
      .test('mustSelect', 'Please select a Loan Purpose', (value: any) => {
        return value !== '-'
      }),
    amountRequested: Yup.number()
      .required(errorMsg.required)
      .min(minAmount, 'Amount range should be between 75,000 - 500,000')
      .max(maxAmount, 'Amount range should be between 75,000 - 500,000'),
    durationInMonths: Yup.number()
      .required(errorMsg.required)
      .min(minDuration, 'Duration range should be between 3 - 12 months')
      .max(maxDuration, 'Duration range should be between 3 - 12 months')
      .test('isInteger', 'Must be an integer', (value: any) => {
        return Number.isInteger(value)
      }),
    companyUEN: Yup.string().required(errorMsg.required),
    companyName: Yup.string().required('Company Name is required'),
    businessMobileNumber: Yup.string()
      .min(11, 'Enter a 8-digit Mobile Number')
      .max(11, 'Enter a 8-digit Mobile Number')
      .required('Mobile Number is required')
      .test('singaporemobileValidation', 'Only singapore number supported', (value: any) => {
        return /\+65\d{8}/g.test(value)
      }),
    businessEmailAddress: Yup.string()
      .required('Business Email Address is required')
      .email('Invalid Email'),
    dateOfIncorporation: Yup.string()
      .required('Date of Incorporation is required')
      .typeError('Date of Incorporation is required'),
    industryType: Yup.string()
      .required('Industry is required')
      .test('mustSelectType', 'Please select an Industry Type', (value: any) => {
        return value !== ''
      }),
    otherType: Yup.string().when('industryType', {
      is: (industryType: string) => industryType === 'other',
      then: Yup.string().required('Please enter your industry type'),
    }),
    turnover: Yup.number()
      .required('Turnover is required')
      .test('turnOverNotZero', 'Turnover cannot be 0', (value: any) => {
        return value !== 0
      }),
    profit: Yup.number()
      .required('Net Operating Income is required')
      .test('notZero', 'Net Operating income cannot be 0', (value: any) => {
        return value !== 0
      }),
    anyOutstandingDebts: Yup.boolean().required(errorMsg.required),
    totalMonthlyInstallmentsToBanks: Yup.number(),
    totalMonthlyInstallmentsToNonBanks: Yup.number(),
    totalRevolvingCreditOutstandingToBanks: Yup.number(),
    totalRevolvingCreditOutstandingToNonBanks: Yup.number(),
    currentLitigationAmount: Yup.number(),
  })

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      /* if(lastPickedDateError){
        formik.setFieldError('dateOfIncorporation', 'Please select valid Date');
        return;
      } */

      const {
        amountRequested,
        durationInMonths,
        loanPurpose,
        companyName,
        businessMobileNumber,
        businessEmailAddress,
        industryType,
        otherType,
        turnover,
        profit,
        totalMonthlyInstallmentsToBanks,
        totalMonthlyInstallmentsToNonBanks,
        totalRevolvingCreditOutstandingToBanks,
        totalRevolvingCreditOutstandingToNonBanks,
        currentLitigationAmount,
        anyOutstandingDebts,
        dateOfIncorporation,
      } = trim(values)

      if (
        anyOutstandingDebts &&
        totalMonthlyInstallmentsToBanks <= 0 &&
        totalMonthlyInstallmentsToNonBanks <= 0 &&
        totalRevolvingCreditOutstandingToBanks <= 0 &&
        totalRevolvingCreditOutstandingToNonBanks <= 0 &&
        currentLitigationAmount <= 0
      ) {
        return
      }

      const principal = amountRequested
      const actualIntrestRate = Number(
        industryOptions.find((o: any) => o.value === industryType)?.pricing
      )
      // Monthly interest rate : Divide by 100 to convert to fraction
      const interestRate = actualIntrestRate / 1200
      const tenure = durationInMonths
      //EMI = P × r × (1 + r) n/ ((1 + r) n - 1) where P= Loan amount, r= interest rate, n=tenure in number of months
      const top = Math.pow(1 + interestRate, tenure)
      const bottom = top - 1
      const ratio = top / bottom
      const emi = (principal * interestRate * ratio).toFixed(2)

      dispatch(fetchStartWithForward())
      updateApplication({
        variables: {
          data: {
            amountRequested: parseFloat(amountRequested),
            durationInMonths: parseInt(durationInMonths),
            interestRate: Number(actualIntrestRate),
            emi: Number(emi),
          },
          where: {
            id: loanApplication.id,
          },
        },
      })

      if (loanEligibility === null) {
        createLoanEligibility({
          variables: {
            data: {
              loanPurpose,
              companyName,
              businessMobileNumber,
              businessEmailAddress,
              industryType,
              otherType,
              dateOfIncorporation,
              turnover: parseInt(turnover),
              profit: parseInt(profit),
              anyOutstandingDebts,
              totalMonthlyInstallmentsToBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToBanks)
                : 0,
              totalMonthlyInstallmentsToNonBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToNonBanks)
                : 0,
              totalRevolvingCreditOutstandingToBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToBanks)
                : 0,
              totalRevolvingCreditOutstandingToNonBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToNonBanks)
                : 0,
              currentLitigationAmount: anyOutstandingDebts ? parseInt(currentLitigationAmount) : 0,
              application: {
                connect: {
                  id: loanApplication.id,
                },
              },
            },
          },
        })
      } else {
        updateLoanElgibility({
          variables: {
            data: {
              loanPurpose,
              companyName,
              businessMobileNumber,
              businessEmailAddress,
              industryType,
              otherType,
              dateOfIncorporation,
              turnover: parseInt(turnover),
              profit: parseInt(profit),
              anyOutstandingDebts,
              totalMonthlyInstallmentsToBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToBanks)
                : 0,
              totalMonthlyInstallmentsToNonBanks: anyOutstandingDebts
                ? parseInt(totalMonthlyInstallmentsToNonBanks)
                : 0,
              totalRevolvingCreditOutstandingToBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToBanks)
                : 0,
              totalRevolvingCreditOutstandingToNonBanks: anyOutstandingDebts
                ? parseInt(totalRevolvingCreditOutstandingToNonBanks)
                : 0,
              currentLitigationAmount: anyOutstandingDebts ? parseInt(currentLitigationAmount) : 0,
            },
            where: {
              id: loanEligibility.id,
            },
          },
        })
      }
      setSubmitting(false)
    },
  })

  /* useEffect(() => {
    if(lastPickedDateError){
      formik.setFieldError('dateOfIncorporation', 'Please select valid Date');
    }

  }, [formik.errors]) */

  return (
    <CompanyVerification
      show={true}
      handleClose={() => {}}
      // goToStep={goToStep}
    />
  )
}

export { Step1Sub3, industryOptions, loanPurpose }
